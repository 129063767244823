/* You can add global styles to this file, and also import other style files */
/*
  Common scss settings
  This file is in the shared project and in the 'main' project (the project that is using shared).
 */

@import 'theme';

@import '~bootstrap/dist/css/bootstrap.css';

@import '~@angular/cdk/overlay-prebuilt.css';

@import '~@fortawesome/fontawesome-svg-core/styles.css';

@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*
@import '~@fullcalendar/common/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';
*/

// @import '~primeng/resources/themes/bootstrap4-dark-blue/theme.css';
// @import '~primeng/resources/themes/bootstrap4-dark-purple/theme.css';

*:focus {
  box-shadow: none !important;
//  border: solid 1px map-get($theme-colors,"primary") !important;
}

// $enable-rounded: true;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
