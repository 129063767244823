// $primary: red; // #007bff; // blue // #0074d9,
$theme-colors: (
  'primary': #0074d9,
  'danger': #ff4136,
);

$enable-rounded: true;

//
// Notes styles
//

.notes-panel {
  border: solid 1px lightgray;
  border-radius: 5px;
}
